import React from "react";
import * as styles from "./PrivacyContainer.module.scss";

const PolicyList = [
  {
    subtitle: "Information collected from Our Website and Purposes",
    desc: (
      <>
        Our website’s server does not automatically validate any specific
        information about individual users on the website unless they supply it.
        Depending on what the users are searching for on our website, we may
        collect precise information for the following purposes:
        <ul className="custom-list">
          <li>
            Job Application (Name, Phone Number, Email, Resume, Job Position and
            About Yourself)
          </li>
          <li>
            New Project (Name, Email, Phone Number, Company Name, Project
            details, References)
          </li>
          <li>Inquiries (Name, Company, Email, Subject, Message)</li>
        </ul>
        Take note that the decision for users to provide information to us is
        always optional. Some information is required to fill in for a better
        understanding of your motivation in visiting our websites and improve
        your user experience. You may choose to email us or make contact with us
        in other ways to provide the information with us. Other than that, we
        use the information for follow-ups or communicate with you about the
        topic that we consider as your interest, via email, phones or live chat.
      </>
    ),
  },
  {
    subtitle: "How We Protect Your Information",
    desc: (
      <p>
        We strive to protect your information with a variety of security
        measures and procedures implemented during user access to their details
        on our website. With our regular malware scanning, your information is
        hereby safe with us and protected from unauthorized access, modification
        or disclosure. Please be aware that we will never request credit card
        numbers or make any payment transactions through our websites. If it’s
        happened, do contact us without hesitation to solve this issue. Your
        personal information is only accessible by our admin, who has special
        access rights to the system and are obligated to keep the information
        confidential. Any sensitive information you shared with us will be
        encrypted through Secure Socket Layer (SSL) technology. We will not use
        any vulnerable scanning to PCI standards.
      </p>
    ),
  },
  {
    subtitle: "The Usage of Cookies",
    desc: (
      <>
        <p>
          Cookies are small files that allow us, as the service provider, to
          recognize your browser and remember some certain information from your
          computer’s hard drive. They will install a temporary memory of your
          details to identify you as a user and customize the web pages
          appropriately. This could only happen if you enable the website or
          service provider’s system to hold a specific amount of data or access
          by the webserver. Otherwise, the server will read the cookie and
          retrieve all the user’s information from the database whenever the
          site is revisited.
        </p>
        <p>
          Our usage of cookies is to understand your activities from our
          website, whether users are interested in applying for a job position
          or simply stopping by to visit our page. They are also used to help us
          accumulate the data about site traffic and site interaction, providing
          insightful information on improvising our site experiences and tools
          for website visitors in the future.
        </p>
        <p>
          To inspect whether your browser is configured to allow cookies, you
          may check through your browser’s settings. Browser’s Help menu will
          guide you to the correct way to modify your cookies. Disabling your
          cookies will not affect the user's experience.
        </p>
      </>
    ),
  },
  {
    subtitle: "Third-Party Disclosure",
    desc: (
      <p>
        Hereby we would like to clarify that we do not sell, trade or transfer
        your personal information to outside parties unless the user received
        advance notice from and agreed to share the information with us. This
        does not include website hosting partners and other parties who assist
        us in operating our website, conducting our business, or serving our
        users, so long as those parties agree to keep this information
        confidential. We may also release information when it’s release is
        appropriate to comply with the law, enforce our site policies, or
        protect ours or others’ rights, property or safety.
      </p>
    ),
  },
  {
    subtitle: "Third-Party Links",
    desc: (
      <p>
        We do not include or offer third-party products or services on our
        website.
      </p>
    ),
  },
  {
    subtitle: "Google Analytics",
    desc: (
      <>
        <p>
          We may conduct web analytics to analyze site traffic and user behavior
          on our website, such as Google Analytics. Google Analytics is a web
          analytics service produced by Google Inc. with tracks and reports of
          website traffic. The information will be generated by their cookie on
          the usage of our website, such as your IP address, URL visited and
          time of the page being viewed. Google will monitor your use of the
          website, assemble reports of the website activities and evaluate the
          performances of the website content, products, etc. Google may
          transfer the information to third parties where required by law or
          process the information on behalf of Google. For more information
          about Google Analytics’s privacy policy, you may refer to{" "}
          <a
            href="https://policies.google.com/privacy?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            this site
          </a>
          .
        </p>
        <h5>Opting Out:</h5>
        <p>
          To opt-out of Google Analytics measurement from our website, users can
          install Google Analytics opt-out browser add-on to disable the
          trackings on them.
        </p>
      </>
    ),
  },
  {
    subtitle: "Facebook Pixels",
    desc: (
      <>
        <p>
          We use Facebook pixel to understand the actions people take on our
          website. Facebook pixel is a snippet of JavaScript code that allows
          website owners to track visitor activity on their website. It operates
          by loading a small library of functions that track visitors and
          identify the action made by them. When some visit your website and
          take an action (for example, purchasing a product), Facebook pixel
          will activate and report this action to the website owner. It’s a
          powerful tool that allows you to create engagement with your audience
          through ad targeting, dynamic ads and conversion optimization.
          Facebook Pixel has enabled us to measure the effectiveness of the
          website, defining custom audience for ad targeting and converting
          higher conversion of our website. However, Facebook may provide
          information to other parties for marketing, advertising or other uses.
          You may view more Facebook pixel’s privacy policy from{" "}
          <a
            href="https://developers.facebook.com/docs/privacy"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <h5>Opting Out:</h5>
        <p>
          If you are planning to implement opt-out code on Facebook Pixel, you
          may refer to this{" "}
          <a
            href="https://www.facebook.com/business/help/471978536642445?id=1205376682832142"
            target="_blank"
            rel="noreferrer"
          >
            Facebook business article
          </a>{" "}
          about the latest changes they have made on their cookies.
        </p>
      </>
    ),
  },
  {
    subtitle: "Personal Data Protection Act 2010 (PDPA)",
    desc: (
      <>
        <p>
          Personal Data Protection Department (PDPD) is an agency under the
          Ministry of Communications and Multimedia Commission (MCMC) was
          established on May 16, 2011 after the Parliament passed the bill
          relating to the Personal Data Protection Act 2010 (PDPA) of Act 709.
          The main responsibility of this department is to oversee the
          processing of personal data of individuals involved in commercial
          transactions by User Data that is not misused and misapplied by the
          parties concerned.
        </p>
        <p>
          Users need to be protected to prevent any form of abuse against the
          storage or processing of personal data of individuals, public and
          private sectors in Malaysia for commercial transactions is stipulated
          under the PDPA. In enforcing the PDPA, JPDP has mandated that all
          Personal Data User Groups consist of individuals or private parties
          unless the Government officially registered for the purpose of
          protecting the rights of consumers and the public.
        </p>
        <p>
          See more at:{" "}
          <a
            href="https://www.pdp.gov.my/jpdpv2/akta-709/personal-data-protection-act-2010/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            https://www.pdp.gov.my/jpdpv2/akta-709/personal-data-protection-act-2010/
          </a>
        </p>
      </>
    ),
  },
  {
    subtitle: "Job Applicants",
    desc: (
      <p>
        Personal data provided in connection with your application for
        employment will be used to determine your suitability for a position
        with Simplicitic Innovations Sdn Bhd. Terms of employment and engagement
        will be considered (if your application is appropriate). Your
        information may be used to monitor our recruitment process and equal
        opportunities policies. Your details are accessed by the job recruiter
        only, without disclosing to any third parties or obtain additional
        information from your institutes, previous employers or agency of
        references. Unsuccessful applications may be retained to match your
        skills to future job opportunities.
      </p>
    ),
  },
  {
    subtitle: "New Projects",
    desc: (
      <p>
        When submitting your project information with us, you have agreed to
        share the information with us regardless if we accept or decline your
        business plan. A meetup will be arranged with clients to discuss the
        business requirements and other relevant information about the
        particular project. If your project is accepted, our team will provide
        services to the utmost abilities and assist you throughout the whole
        progress. We will not disclose any of your project details to third
        parties, unless it is required by the law or with your consent. For
        other projects that we may have declined, we are legally obligated to
        delete your project information from our database permanently, if it no
        longer serves us any purposes.
      </p>
    ),
  },
  {
    subtitle: "Contact Us",
    desc: (
      <>
        <p>
          Please be noted that we will not disclose your personal information to
          any third parties for their direct marketing purposes without your
          consent. As the users, you have the right to opt-out of your
          information if there are any third parties to disclose your details
          for their direct marketing purposes. Let us know anything regarding
          this privacy policy, by contacting us with the information down below.
        </p>
        <h4 className="h5">
          Simplicitic Innovations Sdn. Bhd. (Co. No.: 1094064A)
        </h4>
        <p>
          Unit 2-1, Level 2, The Podium, Tower 3, UOA Business Park
          <br />
          No, 1, Jalan Pengaturcara U1/51a, Seksyen U1
          <br />
          40150 Shah Alam, Selangor D. E.
          <br />
          <strong>or</strong>
          <br />
          <a href="mailto:hello@simplicitic.com" rel="noreferrer">
            hello@simplicitic.com
          </a>
        </p>
      </>
    ),
  },
];

const PrivacyContainer = () => {
  return (
    <div className={`wrapper ${styles.container}`}>
      <div className={styles.title}>
        <h1 className="h2">Privacy Policy</h1>
        <h2 className="h4">(Simplicitic Innovations Sdn Bhd)</h2>
      </div>
      <div className={styles.policyList}>
        <p>
          Your privacy is important to us and we have compiled this Privacy
          Policy to explain how we collect and manage your data and personal
          information online. Our privacy policy prepared in accordance with the
          Personal Data Protection 2010, which discusses how your information is
          collected and used and your choices with respect to your Personal
          Data. Please read carefully to get a clear understanding of how we
          collect, use and protect the data.
        </p>
        {PolicyList.map((item, index) => {
          return (
            <div className={styles.policyItem} key={index}>
              <h3 className="h4">{item.subtitle}</h3>
              {item.desc}
              {item.url}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PrivacyContainer;
